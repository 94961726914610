import React from 'react'

import styles from './Conditions.module.sass'

const Conditions = () => {

	return <section className="prices-explained">
		<h4 className="text-center custom-font">Умови навчання</h4>
		<p>
			Заняття проводяться індивідуально для учнів навчаючихся на музичних інструментах та тих,
			хто займається вокалом. У групах займаються навчаючись театральному мистецтву, цирковому
			мистецтву, образотворчому мистецтву, хореографії, спортивно-бойовій хореографії та у групі
			загального розвитку.
		</p>
		<p>
			Тривалість навчального процесу у 2023-2024 навчальному році:
		</p>
		<ul className={styles.schoolYearInfo}>
			<li>
				І чверть з 1 вересня по 22 жовтня
			</li>
			<li>
				Осінні канікули з 23 жовтня по 29 жовтня
			</li>
			<li>
				ІІ чверть з 30 жовтня по 24 грудня
			</li>
			<li>
				Зимові канікули з 25 грудня по 7 січня
			</li>
			<li>
				ІІІ чверть з 8 січня по 24 березня
			</li>
			<li>
				Весняні канікули з 25 березня по 31 березня
			</li>
			<li>
				ІV чверть з 1 квітня по 31 травня
			</li>
		</ul>
		<p>
			Навчальний процес відбувається згідно з типовими навчальними планами, які визначають
			державний стандарт початкової мистецької освіти, та реалізується відповідно до Законів України
			«Про освіту», «Про позашкільну освіту» та встановлюють вимоги до змісту та обсягу освітньої
			підготовки учнів відповідно до фахового спрямування.
		</p>
		<p>
			Місячний розмір плати за навчання (надання платних послуг) визначений в
			середньому на 9 місяців поточного навчального року, у розрахунок включена оплата за час
			канікул, періодів карантинів, строки яких визначаються місцевими органами виконавчої
			влади, несприятливих погодних умов тощо та час відсутності учня на уроках незалежно від
			обставин.
		</p>
		<p>
			У разі, якщо до 10 числа поточного місяця не буде внесена встановлена плата за
			навчання (надання платних послуг), учень може бути виключеним із закладу.
			При написанні заяви батьки зобов&apos;язуються щомісячно до 10 числа поточного місяця
			вносити визначену плату за навчання (надання платних послуг) з вересня по травень
			включно.
		</p>
	</section>
}

export default Conditions
