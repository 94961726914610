import React from 'react'
import { Helmet } from 'react-helmet'

import CommonLayout from '../../CommonLayout'
import styles from './Oferta.module.sass'

const Oferta = () => {
	return <>
		<Helmet>
			<title>Договір публічної оферти про надання послуг</title>
			<meta name="description" content="Договір публічної оферти школи мистецтв «АРТ ВІВА»" />
		</Helmet>
		<CommonLayout>
			<section className={styles.ofertaSection}>
				<h3>
					Договір публічної оферти<br />
					про надання послуг
				</h3>
				<p>
					<strong>Комунальний заклад спеціалізованої мистецької освіти «Школа мистецтв «АРТ ВІВА»
					Дмитрівської сільської ради Бучанського району Київської області</strong> (надалі «Виконавець»),
					в особі виконуючої обов’язки директора Робакідзе Лілії Вікторівни, яка діє на підставі
					розпорядження №129 Дмитрівської сільської ради, від 9 листопада 2022 р,
					пропонує необмеженому колу фізичних осіб (надалі – «Замовник»), укласти договір
					про надання послуг (надалі «Договір»).</p>
				<p>
					Даний Договір є публічним, відповідно до ст. ст. 633, 634, 641, 642 Цивільного кодексу України,
					та його умови є однакові для всіх Замовників.
				</p>
				<h4>1. Визначення термінів та загальні положення</h4>
				<p>
					1.1. Послуги – надання послуг з дошкільної освіти (освіти, яка передує школі першого ступеня),
					тобто послуг денного догляду за дітьми, що надаються Виконавцем в порядку і
					на умовах, визначених цим Договором.</p>
				<p>
					1.2. Публічна оферта (далі – «Оферта», «Договір») – публічна пропозиція, адресована необмеженому
					колу фізичних осіб, укласти Договір на умовах, що містяться в цій Оферті.</p>
				<p>
					1.3. У цьому договорі Cайт Виконавця – це веб-сторінка в мережі Інтернет за адресою{' '}
					<a href="https://artviva.school">https://artviva.school</a>, яка є офіційним джерелом інформування
					Користувачів послуг про Виконавця та послуги, які їм надаються</p>
				<p>
					1.4. Акцепт – повне, безумовне і беззастережне прийняття Замовником умов в тому вигляді, в якому вони
					викладені в тексті цієї Оферти.</p>
				<p>
					1.5. «Замовник», «Користувач послуг» або «Батьки» розуміється фізична особа, (або декілька фізичних
					осіб), що досягла 18 - річного віку, яка має цивільну дієздатність, яка є батьком, матір’ю, або опікуном
					дитини, яка буде отримувати конкретні послуги дошкільної, або позашкільної освіти в
					КЗСМО «Школа мистецтв «АРТ ВІВА».</p>
				<p>
					1.6. КЗСМО – комунальний заклад спеціалізованої мистецької освіти.</p>
				<p>
					1.7. Сторони – Виконавець та Замовник.</p>
				<h4>2. Предмет Договору</h4>
				<p>
					2.1. Предметом цього Договору є надання Виконавцем, Замовнику, освітніх послуг дошкільної та
					позашкільної освіти, в КЗСМО «Школа мистецтв «АРТ ВІВА», згідно обраному переліку освітніх
					послуг вказаних у формі Заявки на освітні послуги, яка є Додатком № 1 до даного Договору,
					на визначених Договором умовах.
				</p>

				<h4>3. Порядок укладення Договору</h4>
				<p>
					3.1. Договір укладається між Виконавцем і Користувачем  послуг у формі договору
						приєднання (ст. 634 Цивільного кодексу України).</p>
				<p>
					3.2. Перед укладанням вказаного Договору, Користувач
					послуг надає  наступний перелік необхідних документів;<br />
					- копія свідоцтва про народження дитини (з одночасним пред’явленням оригіналу);<br />
					- копія паспорту  батьків,  або осіб які їх замінують (з одночасним пред’явленням оригіналу);<br />
					- медична картка;<br />
					- медична довідка – дозвіл на відвідування басейну (якщо таке відвідування планується).</p>
				<p>
					3.3. Акцептом умов Договору є здійснення Користувачем послуг дій, визначених п. 3.4 Договору.</p>
				<p>
					3.4. Акцепт умов Договору означає повне і беззастережне прийняття Користувачем послуг всіх
					умов Договору, без будь-яких виключень та/або обмежень і рівносильний укладенню двостороннього
					письмового Договору про надання освітніх послуг дошкільної та позашкільної освіти,
					в КЗСМО «Школа мистецтв «АРТ ВІВА», згідно обраному переліку освітніх послуг вказаних
					у формі Заявки на освітні послуги, яка автоматично є Додатком № 1 до
					даного Договору (ч. 2 ст. 642 Цивільного кодексу України).</p>
				<p>
					3.5. Користувач послуг здійснює акцепт Договору після ознайомлення з його умовами викладеними
					в загальнодоступному місці Виконавця, або на сайті Виконавця шляхом сукупного або окремого
					виконання будь-якої з наступних дій:<br />
					- ознайомлення з умовами Договору викладеними в загальнодоступному місці, або на сайті Виконавця;<br />
					- оплата Користувачем послуг, або іншою особою послуг Виконавця;<br />
					- заповнення Користувачем послуг спеціалізованої форми Заявки на освітні послуги;<br />
					- надання документів перелічених у п.4.2. Договору;<br />
					- приведення та самостійне залишення дитини в КЗСМО «Школа мистецтв «АРТ ВІВА»,
						більше ніж на 20 хвилин.
					Будь яка з вказаних дій окремо, яка виконана Користувачем свідчить про те, що Користувач послуг
					ознайомлений з даним Договором і згодний з їхніми умовами в повному обсязі.</p>
				<p>
					3.6. Користувач послуг зобов&apos;язується повідомляти при заповнені Заявки на освітні послуги
					(самостійно вносити) достовірні персональні дані і відомості, що відповідають дійсності.</p>
				<p>
					3.7. Термін акцепту не обмежений.</p>
				<p>
					3.8. Договір вважається укладеним і вступає в силу з моменту акцепту, що визначається датою заповнення
					Заяви на освітні послуги і діє протягом усього терміну отримання освітніх послуг, або до моменту
					розірвання на підставах, визначених умовами Договору та/або нормами чинного законодавства України.</p>
				<p>
					3.9. Укладання Договору означає, що Користувач послуг:<br />
					- у необхідному для нього обсязі ознайомився з функціонуванням і правилами надання
						освітніх послуг;<br />
					- визнає безумовну придатність освітнього персоналу КЗСМО «Школа мистецтв «АРТ ВІВА»,
						приміщень, і правил здійснення всіх дій, описаних в цьому Договорі;<br />
					- приймає всі умови вказаної Угоди без зауважень.
				</p>
				<h4>4. Порядок розрахунків</h4>
				<p>
					4.1. Освітні послуги надаються Користувачеві послуг на попередній платній основі відповідно до Тарифів
						на освітні послуги, які автоматично є Додатком № 2 до даного Договору, вказаних на інформаційному
						стенді або на сайті Виконавця за адресою{' '}
					<a href="https://artviva.school/prices">https://artviva.school/prices</a> (надалі – «Тарифи»).</p>
				<p>
					4.2. Оплата за надання освітніх послуг здійснюється за допомогою платіжного сервісу «LiqPay»
						(посилання міститься на сайті Виконавця), наперед, в розмірі і в терміни, вказані в Тарифах.</p>
				<p>
					4.3. Оплата, внесена Користувачем послуг за освітні послуги, у разі одностороннього припинення договору
					Виконавцем, у зв’язку з порушеннями умов даного Договору, з боку Користувача послуг - не повертається.
				</p>
				<h4>5. Права і обов&apos;язки Виконавця</h4>
				<p>
					<strong>5.1. Виконавець зобов&apos;язаний:</strong>
				</p>
				<p>
					5.1.1. До початку надання освітніх послуг провести з Користувачем послуг ознайомлення з приміщеннями
						закладу і усний інструктаж з правил поведінки батьків і дітей у
						КЗСМО «Школа мистецтв «АРТ ВІВА» і вимог техніки  безпеки.</p>
				<p>
					5.1.2. Організувати діяльність з навчання дитини відповідно до його віку, індивідуальними особливостями,
						змістом освітньої програми;</p>
				<p>
					5.1.3. Розвивати творчі здібності і інтереси дитини;</p>
				<p>
					5.1.4. Здійснювати індивідуальний підхід до дитини;</p>
				<p>
					5.1.5. Зберігати місце за дитиною, при умові оплати згідно тарифів, у випадку його хвороби,
						санаторно-курортного лікування, карантину, відпустки або тимчасової відсутності
						«Батьків» по поважних причинах (хвороба, відрядження).</p>
				<p>
					5.1.6. Надавати консультативну допомогу «Батькам» у вихованні й навчанні дитини;</p>
				<p>
					5.1.7. Навчати дитину по комплексній освітній програмі із залученням висококваліфікованих
						педагогічних кадрів. Зміст програми представлений у Додатку  №1, до даного Договору.</p>
				<p>
					5.1.8. Використати сучасні педагогічні технології й додаткові спеціальні програми;</p>
				<p>
					5.1.9. Виконувати санітарні норми для дошкільних установ.</p>
				<p>
					<strong>5.2. Виконавець має право:</strong>
				</p>
				<p>
					5.2.1. Не допускати до КЗСМО «Школа мистецтв «АРТ ВІВА» осіб у стані алкогольного,
						наркотичного сп&apos;яніння батьків, або супровідних осіб.</p>
				<p>
				5.2.2. Відрахувати дитину з установи при наявності медичного висновку про стан
					здоров&apos;я дитини, що перешкоджає його подальшому перебуванню в
					КЗСМО «Школа мистецтв «АРТ ВІВА», за заявою батьків, а також у випадку тривалої
					відсутності дитини протягом 1 місяця без поважної причини;</p>
				<p>
				5.2.3. Відрахувати дитину з установи при несвоєчасній оплаті за освітні послуги.</p>
				<p>
				5.2.3. Відрахувати дитину з установи при невиконанні «Батьками» (законним представником) умов
					і правил відвідування КЗСМО «Школа мистецтв «АРТ ВІВА»;</p>
				<p>
				5.2.4. Не приймати до відвідування занять хворої дитини;</p>
				<p>
				5.2.5. Не приймати дитину в дитячий сад після його 3-х денної відсутності, без довідки
					лікаря дитячої поліклініки;</p>
				<p>
				5.2.6. Розірвати даний Договір достроково при систематичному невиконанні «Батьком»
					(законним представником) своїх зобов&apos;язань, повідомивши «Батька»
					(законного представника) про це за два тижні.</p>
				<p>
				5.2.7. Виконавець надає освітні  послуги, тільки після повної оплати Користувачем послуг,
					Виконавцю, відповідно до діючого прейскуранта (тарифу).</p>
				<p>
				5.2.8. Вносити в односторонньому порядку зміни в цей Договір з попередньою їх публікацією
					на сайті Виконавця не раніше ніж через 3 (три) робочих дня після публікації.</p>
				<p>
				5.2.9. Не розглядати претензій Користувача послуг і не повертати кошти за освітні послуги у разі,
					якщо це стосуються невиконання (неналежного виконання) Користувачем своїх зобов&apos;язань по Договору.
				</p>

				<h4>6. Права і обов&apos;язки Користувача послуг</h4>
				<p>
					<strong>6.1. Користувач послуг зобов&apos;язаний:</strong>
				</p>
				<p>
				6.1.1. Перед оплатою послуг ознайомитися з цим Договором. упродовж усього періоду користування освітніми
					послугами  дотримуватися умов цього Договору.</p>
				<p>
				6.1.2. Заповнити бланк Заяви та Анкету на освітні послуги, шляхом правдивого заповнення бланку
					Заяви та Анкети, та здати заповнені бланки Заяви та Анкети на освітні послуги представнику Виконавця,
					відповідальному за прийом та оформлення дітей для подальшого навчання в
					КЗСМО «Школа мистецтв «АРТ ВІВА».</p>
				<p>
				6.1.3. Перед початком надання освітніх послуг уважно вислухати інструктаж і у випадку виникнення
					яких-небудь питань щодо правил поводження і безпеки, задати їх до початку надання освітніх послуг
					співробітнику Виконавця, що проводив інструктаж, і одержати на них вичерпні відповіді.
					Підтвердити факт проходження усного інструктажу особистим підписом на Заяві на освітні послуги.</p>
				<p>
				6.1.4. Дотримувати правил відвідування КЗСМО «Школа мистецтв «АРТ ВІВА» і даний Договір.</p>
				<p>
				6.1.5. Вносити плату за навчання дитини в КЗСМО «Школа мистецтв «АРТ ВІВА» у встановлений
					термін до 1 числа поточного місяця, заздалегідь сповіщати адміністрацію про причини відсутності дитини,
					щодня особисто передавати й забирати дитини у вихователя, не передавати дитини особам
					не досягли 16 років, особам у стані алкогольного та наркотичного сп&apos;яніння,
					особам психічно- нездоровим, проявляти повагу до працівників
					КЗСМО «Школа мистецтв «АРТ ВІВА»;</p>
				<p>
				6.1.6. Інформувати адміністрацію КЗСМО «Школа мистецтв «АРТ ВІВА» про майбутню
					відсутність дитини протягом одного дня.</p>
				<p>
				6.1.7. Повідомляти про хвороби дитини, вчасно документально оформляти відсутність дитини
					в КЗСМО «Школа мистецтв «АРТ ВІВА».</p>
				<p>
				6.1.8. Інформувати старшу медсестру про початок відвідування КЗСМО «Школа мистецтв «АРТ ВІВА»
					після хвороби, відпустки.</p>
				<p>
				6.1.9. Взаємодіяти із КЗСМО «Школа мистецтв «АРТ ВІВА» в усіх напрямках
					виховання й навчання дитини.</p>
				<p>
				6.1.10. Виконувати рішення адміністративної ради КЗСМО «Школа мистецтв «АРТ ВІВА».</p>
				<p>
				6.1.11. У ході отримання освітніх послуг виконувати законні вимоги персоналу Виконавця
					і умов цього Договору.</p>
				<p>
					<strong>6.2. Користувач  послуг має право:</strong>
				</p>
				<p>
				6.2.1. Користуватись послугами згідно умов даного Договору.</p>
				<p>
				6.2.2. Вимагати виконання Уставу КЗСМО «Школа мистецтв «АРТ ВІВА» й умов даного Договору.</p>
				<p>
				6.2.3. Вибирати види додаткових послуг;</p>
				<p>
				6.2.4. Розірвати даний Договір в однобічному порядку за умови попереднього повідомлення про це
					адміністрацію КЗСМО «Школа мистецтв «АРТ ВІВА» за 2 тижні.</p>
				<p>
				6.2.5. Вносити оплату Виконавцю будь-яким з передбаченим Договором або діючим законодавством способом.</p>

				<h4>7. Правила відвідування, поводження і безпеки в КЗСМО «Школа мистецтв «АРТ ВІВА»</h4>
				<p>
					<strong>7.1. До відвідування КЗСМО «Школа мистецтв «АРТ ВІВА» допускаються:</strong>
				</p>
				<p>
					7.1.1 «Батьки» (законні представники) дитини, довірені особи, особи в разовому порядку
						по заяві підписаній Директором Установи.</p>
				<p>
					7.1.2. Не рекомендується відвідувати Установу під час проведення занять, забирати
						дитину пізніше указаного строку.</p>
				<p>
					7.1.3. Користувач послуг, їх довірені особи, діти та інші відвідувачі сторони повинні
						неухильно виконувати всі вказівки персоналу Виконавця.</p>
				<p>
					<strong>7.2. Одяг і екіпірування.</strong>
				</p>
				<p>
					7.2.1. Дитина при відвідуванні КЗСМО «Школа мистецтв «АРТ ВІВА» повинна бути
						в охайному виді:<br />
					- чисто вимита і причесана;<br />
					- у чистому одязі і взутті і одягнена по сезону;<br />
					- с носовою хусткою і гребінцем;<br />
					- с запасною нижньою білизною;<br />
					- повинна мати рушник, плавки, гумову шапочку, гумові тапочки для басейну
						(у разі надання таких послуг);<br />
					- повинна мати спортивну форму для занять оздоровчою гімнастикою.</p>
				<p>
					7.2.3. Необхідно вийняти з кишень одягу будь-які тверді і гострі предмети,
						а також мобільні телефони, гаманці та інші коштовні речі.</p>
				<p>
					7.2.4. Перед прибуттям дитини до закладу перевірити і зав&apos;язати шнурки, перевірити,
						щоб ніякі інші частини одягу (шарф, пояс, волосся і т.п.) не могли зачепитись за
						щось і призвести до небезпечних ситуацій.
				</p>
				<h4>8. Відповідальність сторін</h4>
				<p>
					8.1. Користувач послуг згоден з тим, що Виконавець не несе будь якої майнової відповідальності
						у випадку спричинення йому матеріальної шкоди (у тому числі моральної шкоди) або отримання ним
						будь яких тілесних ушкоджень, каліцтва або смерті Користувача, внаслідок порушенням ним,
						або будь якою третьої особою, правил і умов даного Договору.</p>
				<p>
					8.2. Виконавець несе відповідальність перед Користувачем послуг тільки у розмірі
						суми Передоплати, документально підтвердженої, зокрема, чеком, квитанцією про банківський
						переказ або прибутковим ордером.</p>
				<p>
					8.3. Виконавець не несе відповідальності за збитки Користувача послуг, що виникли в результаті:
						невірного заповнення Заяви на освітні послуги, порушення Користувачем послуг встановленого
						порядку користування і експлуатації обладнання та поведінки, та відмови в обслуговуванні
						Користувача послуг відповідно до п. 6.2.1. даного Договору;</p>
				<p>
					8.4. Виконавець несе відповідальність тільки за ті зобов’язання які указані в Договорі,
						та в Законах України про дошкільну та позашкільну освіту.</p>
				<p>
					8.5. Користувач послуг несе всю відповідальність за достовірність відомостей, вказаних їм
						в Анкеті і Заяві на освітні послуги. У випадку якщо Користувач послуг не вказав або невірно
						вказав персональні дані, Виконавець не відповідає за збитки Користувача послуг, понесені
						в результаті відмови в поверненні передоплати та/або здійснення інших дій внаслідок
						неможливості правильної ідентифікації Користувача послуг.</p>
				<p>
					8.6. У інших випадках невиконання або неналежного виконання своїх зобов&apos;язань за
						Договором, Сторони несуть відповідальність відповідно до законодавства України,
						з урахуванням умов Договору.</p>
				<p>
					8.7. Сторони звільняються від відповідальності за повне або часткове невиконання своїх
						зобов&apos;язань за даним Договором, якщо це стало наслідком обставин непереборної сили,
						що виникли після набуття чинності даного Договору в результаті подій надзвичайного характеру,
						які не могли бути передбаченими та які неможливо було запобігти розумними заходами.</p>
				<p>
					8.8. У випадку порушення  Користувачем послуг умов цього Договору Виконавець не несе
						відповідальності за  ті зобов’язання які указані в Договорі.</p>
				<p>
					8.9. За порушення кожного з пунктів дійсних умов цього Договору, Виконавець залишає
						за собою право або не допустити дитину Користувача послуг до відвідування занять у
						КЗСМО «Школа мистецтв «АРТ ВІВА», або заборонити входити на територію
						КЗСМО «Школа мистецтв «АРТ ВІВА», особам які порушили умови цього Договору.
						При цьому гроші, сплачені за послуги, такому Користувачу послуг не компенсуються.
						Рішення про недопущення дитини до занять, або заборону входу Користувачу і його
						довіреним особам приймає керівництво Виконавець. Рішення якого є остаточним.
				</p>
				<h4>9. Зміна і розірвання Договору</h4>
				<p>
					9.1. Виконавець має право в односторонньому порядку вносити зміни в Договір,
						шляхом публікації змін на сайті Виконавця. Зміни вступають в силу з моменту
						публікації, якщо інший термін вступу змін в силу не визначений додатково при їх публікації.</p>
				<p>
					9.2. При внесенні Виконавцем у Договір істотних змін, що впливають на можливість
						використання послуг Користувачем послуг, Виконавець повідомляє про них Користувача,
						шляхом публікації їх на сайті Виконавця не менше, ніж за 3 (три) дні до вступу змін в силу.</p>
				<p>
					9.3. Договір може бути розірвано у наступних випадках:</p>
				<p>
					9.3.1. Користувачем послуг в односторонньому порядку шляхом письмової відмови
						від надання освітніх послуг.</p>
				<p>
					9.3.2. На інших підставах, передбачених цим Договором та/або чинним законодавством України.</p>
				<p>
					9.3.3. Зміна умов Договору з ініціативи Користувача може мати місце тільки до акцепту
						користування послугою, передбаченого в п. 3.5 Договору.
				</p>
				<h4>10. Вирішення суперечок</h4>
				<p>
					10.1. Всі суперечки і розбіжності, що виникли або могли виникнути з приводу даного Договору,
						підлягають вирішенню шляхом переговорів на підставі письмової заяви Користувача послуг,
						з доданням чеку (квитанції) про сплату освітньої послуги.</p>
				<p>
					10.2. Виконавець після отримання від Користувача послуг претензії зобов&apos;язаний
						протягом 20 (двадцяти) днів задовольнити заявлені в претензії вимоги або направити
						Користувачеві послуг мотивовану відмову.</p>
				<p>
					10.3. У випадку якщо суперечка, що виникла не буде розв’язана в претензійному порядку,
						будь-яка із Сторін має право звернутися за розв’язанням суперечки до суду,
						за місцем знаходження Виконавця.</p>
				<h4>11. Обробка та збір персональних даних.</h4>
				<p>
					11.1. Замовник дає згоду на використання і обробку своїх персональних даних в
						адміністративно-правових цілях. Згода надається щодо персональних даних на здійснення
						будь-яких дій, допустимих відповідно до законодавства, і які необхідні для виконання
						цього Договору, включаючи: збір, систематизацію, зберігання, уточнення (оновлення, зміну),
						використання, передачу (надання, доступ), включаючи транскордонну передачу, знеособлення,
						блокування, знищення; з використанням і без використання засобів автоматизації як
						способів обробки персональних даних. При цьому Замовник дає згоду на передачу своїх
						персональних даних третім особам в мінімально необхідних обсягах з метою виконання
						вимог законодавства та даних договірних зобов’язань з дотриманням вимог
						чинного законодавства.</p>
				<p>
					11.2. Виконавець гарантує, що одержані у процесі виконання цього договору, персональні
						дані Замовника будуть захищені та не будуть неправомірно розповсюджені та надані
						у володіння третіх осіб, а лише у порядку та у спосіб визначених Законом.</p>
				<p>
					11.3. Виконавець використовує загальноприйняті стандарти технологічного та операційного
						захисту інформації та персональних даних від втрати, неправильного використання,
						зміни або знищення. Однак, не дивлячись на всі зусилля, Виконавець не може
						гарантувати абсолютну захищеність від будь-яких загроз, що виникають поза межами
						регулювання Виконавця. Виконавець забезпечує застосування всіх відповідних зобов&apos;язань
						щодо дотримання конфіденційності, а також технічних і організаційних заходів безпеки
						для запобігання несанкціонованому або незаконному розголошенню або обробці
						такої інформації та даних, їх випадковій втраті, знищенню або пошкодженню.
						Виконавець надає доступ до інформації і персональних даних тільки уповноваженим працівникам,
						які дали згоду на забезпечення конфіденційності такої інформації та даних відповідно
						до вимог Закону. Поширення персональних даних без згоди суб&apos;єкта персональних даних
						або уповноваженої ним особи дозволяється у випадках, визначених законом, і лише
						(якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини.</p>
				<p>
					11.4. Виконавець не здійснює передачу персональних даних третім особам, крім випадків,
						коли така передача є вимогою законодавства, на прохання суб&apos;єкта персональних
						даних або в інших випадках, викладених в цій Політиці. Виконавець розуміє,
						що особиста інформація є цінністю і невід&apos;ємним змістом, в тому числі,
						особистих немайнових прав будь-якої фізичної особи, тому вживає всіх можливих
						заходів для захисту особистої інформації користувачів, добровільно і усвідомлено
						переданої останніми Виконавцю. На Сайті можуть бути посилання на інші веб-сайти
						(виключно в інформаційних цілях). При переході по посиланню на інші веб-сайти дія
						цієї Політики на такі сайти поширюватися не буде.
						У зв&apos;язку з чим Виконавець рекомендує переглядати політику в сфері конфіденційності
						і персональних даних кожного веб-сайту перед тим, як передавати будь-які персональні дані,
						за якими Вас можуть ідентифікувати.</p>
				<p>
					11.5. Порядок доступу до персональних даних третіх осіб визначається умовами згоди
						користувача, наданої володільцю персональних даних, на обробку цих даних або відповідно
						до вимог закону. Користувач має право на одержання будь-яких відомостей про себе
						у будь-якого суб&apos;єкта відносин, пов&apos;язаних з персональними даними, за умови
						зазначення прізвища, ім&apos;я та по батькові, місця проживання (місця перебування)
						і реквізитів документа, що посвідчує фізичну особу, яка подає запит, крім випадків,
						установлених законом. Доступ користувача до даних про себе здійснюється безоплатно.
				</p>
				<h4>12. Інші положення</h4>
				<p>
					12.1. Користувач послуг гарантує, що всі умови цього Договору йому зрозумілі, і він
						приймає їх безумовно і в повному обсязі.</p>
				<p>
					12.2. У частині, не врегульованій цим Договором, Виконавець і Користувач послуг
						зобов&apos;язуються керуватися нормами, встановленими діючим законодавством  України.</p>
				<p>
					12.3. Виконавець за цим договором є: <strong>Комунальний заклад спеціалізованої мистецької
						освіти «Школа мистецтв «АРТ ВІВА» Дмитрівської сільської ради Бучанського району Київської
						області</strong>,<br />
						код ЄДРПОУ: 37074990,<br />
						Свідоцтво про державну реєстрацію юридичної особи Серія А01 № 733126;<br />
						видана Києво-Святошинською районною державною адміністрацією київської області
						від 01.07.2010 р.</p>
				<p>
					12.4. Адреса Виконавця: <strong>08122, Київська обл., Києво-Святошинський район,
						с. Шпитьки, вул. Господарська, 3.</strong>
				</p>
			</section>
		</CommonLayout>
	</>
}

export default Oferta
