/**
	* debounce function
	* use inDebounce to maintain internal reference of timeout to clear
*/
export const debounce = (func, delay) => {
	let inDebounce
	return function() {
		const context = this
		const args = arguments
		clearTimeout(inDebounce)
		inDebounce = setTimeout(() =>
			func.apply(context, args)
		, delay)
	}
}
