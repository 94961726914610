import React from 'react'

const FourZeroFour = () => {
	return (
		<div className="text-center custom-font">
			<h1>404</h1>
			<h5 className="custom-font">Вибачте, нам не вдалося знайти те, що ви шукали.</h5>
		</div>
	)
}

export default FourZeroFour
