import React from 'react'
import { Helmet } from 'react-helmet'

import CommonLayout from '../../CommonLayout'
import styles from './Oferta.module.sass'

const PrivacyPolicy = () => {

	return <>
		<Helmet>
			<title>Політика конфіденційності</title>
			<meta name="description" content="Політика конфіденційності школи мистецтв «АРТ ВІВА»" />
		</Helmet>
		<CommonLayout>
			<section className={styles.ofertaSection}>
				<h3>
					Політика конфіденційності
				</h3>
				<p className='text-center'>
					<em><strong>Обробка та збір персональних даних</strong></em>
				</p>
				<p>
					1. Замовник дає згоду на використання і обробку своїх персональних даних в
					адміністративно-правових цілях. Згода надається щодо персональних даних на здійснення
					будь-яких дій, допустимих відповідно до законодавства, і які необхідні для виконання
					цього Договору, включаючи: збір, систематизацію, зберігання, уточнення (оновлення, зміну),
					використання, передачу (надання, доступ), включаючи транскордонну передачу, знеособлення,
					блокування, знищення; з використанням і без використання засобів автоматизації як
					способів обробки персональних даних. При цьому Замовник дає згоду на передачу своїх
					персональних даних третім особам в мінімально необхідних обсягах з метою виконання
					вимог законодавства та даних договірних зобов’язань з дотриманням вимог
					чинного законодавства.
				</p>
				<p>
					2. Виконавець гарантує, що одержані у процесі виконання цього договору, персональні
					дані Замовника будуть захищені та не будуть неправомірно розповсюджені та надані
					у володіння третіх осіб, а лише у порядку та у спосіб визначених Законом.
				</p>
				<p>
					3. Виконавець використовує загальноприйняті стандарти технологічного та операційного
					захисту інформації та персональних даних від втрати, неправильного використання,
					зміни або знищення. Однак, не дивлячись на всі зусилля, Виконавець не може
					гарантувати абсолютну захищеність від будь-яких загроз, що виникають поза межами
					регулювання Виконавця. Виконавець забезпечує застосування всіх відповідних зобов&apos;язань
					щодо дотримання конфіденційності, а також технічних і організаційних заходів безпеки
					для запобігання несанкціонованому або незаконному розголошенню або обробці
					такої інформації та даних, їх випадковій втраті, знищенню або пошкодженню.
					Виконавець надає доступ до інформації і персональних даних тільки уповноваженим працівникам,
					які дали згоду на забезпечення конфіденційності такої інформації та даних відповідно
					до вимог Закону. Поширення персональних даних без згоди суб&apos;єкта персональних даних
					або уповноваженої ним особи дозволяється у випадках, визначених законом, і лише
					(якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини.
				</p>
				<p>
					4. Виконавець не здійснює передачу персональних даних третім особам, крім випадків,
					коли така передача є вимогою законодавства, на прохання суб&apos;єкта персональних
					даних або в інших випадках, викладених в цій Політиці. Виконавець розуміє,
					що особиста інформація є цінністю і невід&apos;ємним змістом, в тому числі,
					особистих немайнових прав будь-якої фізичної особи, тому вживає всіх можливих
					заходів для захисту особистої інформації користувачів, добровільно і усвідомлено
					переданої останніми Виконавцю. На Сайті можуть бути посилання на інші веб-сайти
					(виключно в інформаційних цілях). При переході по посиланню на інші веб-сайти дія
					цієї Політики на такі сайти поширюватися не буде.
					У зв&apos;язку з чим Виконавець рекомендує переглядати політику в сфері конфіденційності
					і персональних даних кожного веб-сайту перед тим, як передавати будь-які персональні дані,
					за якими Вас можуть ідентифікувати.
				</p>
				<p>
					5. Порядок доступу до персональних даних третіх осіб визначається умовами згоди
					користувача, наданої володільцю персональних даних, на обробку цих даних або відповідно
					до вимог закону. Користувач має право на одержання будь-яких відомостей про себе
					у будь-якого суб&apos;єкта відносин, пов&apos;язаних з персональними даними, за умови
					зазначення прізвища, ім&apos;я та по батькові, місця проживання (місця перебування)
					і реквізитів документа, що посвідчує фізичну особу, яка подає запит, крім випадків,
					установлених законом. Доступ користувача до даних про себе здійснюється безоплатно.
				</p>
			</section>
		</CommonLayout>
	</>
}

export default PrivacyPolicy
